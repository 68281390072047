<template>
  <div style="backgroundImage: linear-gradient(to right, #eaf6f8, #f6fcfc)">
    <iframe
      id="iframeMain"
      src="https://sandbox-env.openc.pro/widget-page/?widgetId=test8F7t8nQf"
      style="width: 100%;margin-top: 40px;"
      frameborder="no"
      border="0"
      height="950px"
    ></iframe>
  </div>
</template>

<script>
export default {
	data() {
	  return {
	    height: "950px",
	  };
	},
	methods: {
	  widthChange() {
	    if (document.body.clientWidth < 768) {
	      this.height ="800px";
	    } else{
			this.height ="950px";
		}
	  },
	},
	created() {
	 /* this.widthChange();
	  window.onresize = () => {
	    this.widthChange();
	  }; */
	},
};
</script>

<style>
</style>